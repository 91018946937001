import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Container from '@material-ui/core/Container'
import Layout from '../components/Layout'
import Breadcrumb from '../components/Breadcrumb'
import Content, { HTMLContent } from '../components/Content';



export const GrowthPageTemplate = ({ 
  title,
  helmet,
  content,
  contentComponent
}) => {
  const GrowthContent = contentComponent || Content

  return (
    <section className="page growth">
      <Breadcrumb path='/personlig-utveckling/' title='Personlig utveckling'/>
      {helmet || ''}
      <div className="main-title d-flex d-lg-none" style={{background: '#51C2B9', color: 'white'}}>
        <h2>{title}</h2>
      </div>
      <Container>
       <div className="content">
        <GrowthContent content={content}/>
       </div>
      </Container>
    </section>
  )
}

GrowthPageTemplate.propTypes = {
  title: PropTypes.string.isRequired
}

const GrowthPage = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <GrowthPageTemplate
        helmet={
          <Helmet titleTemplate="%s | Kvarntakt20">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
              />
          </Helmet>
        }
        content={post.html}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        color={post.frontmatter.color}
        image={post.frontmatter.image}
      />
    </Layout>
  )
}

GrowthPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  })
}

export default GrowthPage

export const GrowthPageQuery = graphql`
  query GrowthPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        color
        image {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      
    }
  }
`
